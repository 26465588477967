import React, { useEffect, useRef, useState } from 'react';
import { Router, Route, Switch, match } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Provider, useSelector, useDispatch } from 'react-redux';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import axios, { AxiosResponse } from 'axios';
import { History } from 'history';
import LuigiClient from '@luigi-project/client';
import { setTheme as setUi5Theme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import { ThemeProvider, MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import '@ui5/webcomponents-icons/dist/Assets-static';
import '@eureka/ui-managers/src/styles/layout.css';

import createHistory from './common/history';
import routeConfig from './common/routeConfig';
import { store, RootState, setIsDxp } from './common/redux';
import { renderRouteConfigV3, listenToEventBus } from './App.helper';
import { MicroFrontend } from './features/components';
import { getRandom, getURLParam, setDocumentLang } from './common/Utils';
import helper from './common/helpers';
import hackRouter from './common/hackRouter';
import { registryLuigiEvents } from './common/luigi';
import {
  AppState,
  SetAppState,
  MFE,
  SetMFE,
  ConfigJson,
  UserInfo,
  UserRef,
  CSRFTokenInfo,
  FetchFunctions,
  Settings,
  isPromiseFulfilledResult,
  isPromiseRejectedResult,
} from './types';

type Props = Omit<FetchFunctions, 'usingMock'>;
type ConfigJsonRes = AxiosResponse<ConfigJson>;
type UserInfoRes = AxiosResponse<UserInfo>;
type CSRFTokenInfoRes = AxiosResponse<CSRFTokenInfo>;
type FetchParams = Promise<ConfigJson> | Promise<UserInfo> | Promise<CSRFTokenInfo>;
type FetchRes = ConfigJsonRes | UserInfoRes | CSRFTokenInfoRes;

const { MessageToast } = eureka.controls;
const { initI18n, setLanguage, getUi5Language, getLocation } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig, getConfig, setCsrfToken } = eurekaMgrs.ConfigManager;
const { setLanguage: configManagerSetLanguage, getLanguage: configManagerGetLanguage } =
  eurekaMgrs.ConfigManager;
const { getDefaultThemeId, getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

let isDxp = window.self !== window.top;
let lng = 'en-US';
let themeId = getDefaultThemeId();
const testingLng = getURLParam(window.location.search, 'sap-language');
const testingThemeId = getURLParam(window.location.search, 'sap-ui-theme');

const processSettledResult = (
  result: PromiseSettledResult<FetchRes>,
  successCb: (any) => void,
  failedCb: (any) => void,
) => {
  if (isPromiseFulfilledResult(result)) {
    successCb && successCb(result.value);
  } else if (isPromiseRejectedResult(result)) {
    failedCb && failedCb(result.reason);
    throw result.reason;
  }
};

const onFetchConfigSuccess = ({
  manifest,
  state,
  setState,
  setMicroFrontends,
}: {
  manifest: ConfigJson;
  state: AppState;
  setState: SetAppState;
  setMicroFrontends: SetMFE;
}) => {
  const shell = getLocation(manifest, manifest['shell-ui']);
  const microFrontends: MFE[] = [];

  console.log('nick manifest', manifest);

  manifest.components.forEach((component) => {
    const host = getLocation(manifest, component);
    microFrontends.push({
      name: component.config.app,
      host,
      routers: component.config.routers,
    });
  });
  // config = manifest;
  setState((prevState) => ({
    ...prevState,
    config: manifest,
  }));

  console.log('nick microFrontends', microFrontends);

  setMicroFrontends(microFrontends);
  // add app config into config manager
  addConfig('appConfig', manifest);
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en-US',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );
  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    setState((prevState) => ({
      ...prevState,
      initializing: false,
    }));
  }
};

const onFetchConfigFailed = ({
  error,
  state,
  setState,
}: {
  error: boolean;
  state: AppState;
  setState: SetAppState;
}) => {
  setState((prevState) => ({
    ...prevState,
    // initializing: false,
    fetchConfigError: error,
  }));
};

const onFetchAuthSuccess = ({ auth, user }: { auth: UserInfoRes; user: UserRef }) => {
  console.log('nick onFetchAuthSuccess');
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

export const onFetchAuthFailed = ({
  error,
  state,
  setState,
}: {
  error: any;
  state: AppState;
  setState: SetAppState;
}) => {
  console.log('nick onFetchAuthFailed');
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=dxp';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState((prevState) => ({
      ...prevState,
      authUserError: error,
    }));
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState((prevState) => ({
      ...prevState,
      initializing: false,
    }));
  }
};

const onFetchCsrfSuccess = ({ csrf }: { csrf: CSRFTokenInfoRes }) => {
  setCsrfToken(csrf?.data?.token);
};

const onFetchCsrfFailed = ({
  error,
  user,
  state,
  setState,
}: {
  error: any;
  user: UserRef;
  state: AppState;
  setState: SetAppState;
}) => {
  setCsrfToken('fakecsrftoken');
  setState((prevState) => ({
    ...prevState,
    settings: {},
    user: user.current,
    fetchConfigError: false,
  }));
};

export const onFetchFinally = ({ state, setState }: { state: AppState; setState: SetAppState }) => {
  if (testingLng) {
    lng = testingLng;
  }
  if (testingThemeId) {
    themeId = testingThemeId;
  }

  if (isDxp) {
    setThemeId(themeId);
    setUi5Theme(getThemeId());
    setLanguage(lng);
    configManagerSetLanguage(lng);
    setUi5Language(getUi5Language(lng));
    setDocumentLang(document, lng);
  }
  // set initialization done
  setState((prevState) => ({
    ...prevState,
    initializing: false,
  }));
};

const luigiClientInitListener = (initialContext, dispatch: Dispatch) => {
  console.log('Luigi Client Initialized.');
  // TODO:
  // window.isDxp = true;
  isDxp = true;
  dispatch(setIsDxp(true));
  addConfig('isDxp', true);
  addConfig('dxpContext', initialContext);
  const { token, goBackContext } = initialContext;
  if (token) {
    addConfig('dxpToken', token);
  }
  if (goBackContext?.remotePromiseId) {
    const { remotePromiseId, action } = goBackContext;
    const { resolve, reject } = helper.unsubscribe(remotePromiseId);
    if (action && action === 'resolve') {
      resolve && resolve({ msg: 'Luigi Modal Closed', ...goBackContext });
    } else {
      reject && reject({ msg: 'Luigi Modal Closed', ...goBackContext });
    }
  }
  const lang = LuigiClient.uxManager().getCurrentLocale();
  const themeId = LuigiClient.uxManager().getCurrentTheme();
  if (lang) {
    // TODO:
    // setLanguage(lang);
    configManagerSetLanguage(lang);
    setDocumentLang(document, lang);
  }
  if (themeId) {
    setThemeId(themeId);
  }
  console.log('Initial Context', initialContext);
  console.log('Current Locale', lang);
  console.log('Current Theme', themeId);
};

const LuigiClientContextUpdateListener = (updatedContext) => {
  // console.log('Luigi Context Updated.', updatedContext);
  addConfig('dxpContext', updatedContext);
  const { token } = updatedContext;
  if (token) {
    addConfig('dxpToken', token);
  }
};

const onDxpReady = async ({ fetchConfig, state, setState, setMicroFrontends, user }) => {
  try {
    const res = await fetchConfig();
    onFetchConfigSuccess({
      manifest: res?.data,
      state,
      setState,
      setMicroFrontends,
    });
    onFetchAuthSuccess({ auth: {}, user });
    onFetchFinally({ state, setState });
  } catch (error) {
    MessageToast.error(error?.error || error?.message);
  }
};

const onApplicationReady = async ({
  fetchConfig,
  fetchAuth,
  fetchCsrf,
  state,
  setState,
  setMicroFrontends,
  user,
}) => {
  const [configRes, authRes, csrfRes] = await Promise.allSettled<FetchRes>([
    fetchConfig(),
    fetchAuth(),
    fetchCsrf(),
  ]);
  try {
    processSettledResult(
      configRes,
      (value) =>
        onFetchConfigSuccess({
          manifest: value?.data,
          state,
          setState,
          setMicroFrontends,
        }),
      (error) => onFetchConfigFailed({ error, state, setState }),
    );
    processSettledResult(
      authRes,
      (value) => onFetchAuthSuccess({ auth: value, user }),
      (error) => onFetchAuthFailed({ error, state, setState }),
    );
    processSettledResult(
      csrfRes,
      (value) => onFetchCsrfSuccess({ csrf: value }),
      (error) => onFetchCsrfFailed({ error, user, state, setState }),
    );
  } catch (error) {
    MessageToast.error(error?.error || error?.message);
  }
  onFetchFinally({ state, setState });
};

async function onGetProject() {
  if (window.location.pathname) {
    const project = window.location.pathname.split('/')[2];
    window.localStorage.setItem('project', project);
    addConfig('project', project);
    console.log('current project', project);
    axios.get('/api/dev-info/v1/user').then((data) => {
      window.localStorage.setItem('projectList', JSON.stringify(data?.data?.organizations));
    });
    // if (project !== '') {
    //   addConfig('organization', organization);
    //   window.localStorage.setItem('organization', organization);
    // } else {
    //   axios
    //     .get('/api/dev-info/v1/user')
    //     .then((data) => {
    //       if (data.data?.organizations?.length) {
    //         addConfig('organization', data.data?.organizations[0].alias);
    //         window.localStorage.setItem('organization', data.data?.organizations[0].alias);
    //         addConfig('organizationList', data.data?.organizations);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }
}

export const loader = () => <div>Loading...</div>;

export const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner className="page-load-spinner" />
    </div>
  );
};

export const renderError = (msg: string) => (
  <MessageStrip
    style={{ marginTop: '10px', marginRight: '10px' }}
    design={MessageStripDesign.Negative}
    hideIcon={false}
    hideCloseButton
  >
    {msg}
  </MessageStrip>
);

export const MicroFrontendWrapper = ({
  history,
  match,
  host,
  name,
  config,
  settings,
  user,
}: {
  history: History;
  match: match;
  host: string;
  name: string;
  config: ConfigJson;
  settings: Settings;
  user: UserRef;
}) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      // eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({
  mfeRouters,
  history,
  config,
  settings,
  user,
}: {
  mfeRouters: MFE[];
  history: History;
  config: ConfigJson;
  settings: Settings;
  user: UserRef;
}) => {
  const routes: React.ReactElement[] = [];
  mfeRouters.forEach((mfe) => {
    mfe.routers?.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              name={mfe.name}
              host={mfe.host}
              history={history}
              config={config}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

export const renderMfes = ({
  state,
  user,
  microFrontends,
  isDxp,
}: {
  state: AppState;
  user: UserRef;
  microFrontends: MFE[];
  isDxp: boolean;
}) => {
  const history = createHistory(isDxp);
  const { config, settings } = state;
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfeRouters: microFrontends,
    history,
    config,
    settings: state.settings,
    user,
  });

  console.log('nick containerRoutes', containerRoutes);
  console.log('nick microFrontendRoutes', microFrontendRoutes);

  if (isDxp) {
    return (
      // <Provider store={store}>
      <Router history={history}>
        <Switch>
          {microFrontendRoutes}
          {containerRoutes}
        </Switch>
      </Router>
      // </Provider>
    );
  } else {
    return (
      <ThemeProvider>
        {/* <Provider store={store}> */}
        <Router history={history}>
          <Switch>
            {microFrontendRoutes}
            {containerRoutes}
          </Switch>
        </Router>
        {/* </Provider> */}
      </ThemeProvider>
    );
  }
};

const App: React.FC<Props> = ({ fetchConfig, fetchAuth, fetchCsrf }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState<AppState>({
    initializing: true,
    fetchConfigError: false,
    authUserError: false,
    config: {},
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState<MFE[] | []>([]);
  const user = useRef<UserInfo | Record<string, unknown>>({});

  useEffect(() => {
    // let initListener: number;
    // let contextUpdateListener: string;
    // if (isDxp) {
    // LuigiClient.luigiClientInit();
    const initListener = LuigiClient.addInitListener((initialContext) =>
      luigiClientInitListener(initialContext, dispatch),
    );
    const contextUpdateListener = LuigiClient.addContextUpdateListener(
      LuigiClientContextUpdateListener,
    );
    // }
    return () => {
      contextUpdateListener && LuigiClient.removeContextUpdateListener(contextUpdateListener);
      initListener && LuigiClient.removeInitListener(initListener);
    };
  }, []);

  useEffect(() => {
    addConfig('application', 'dxp');
    if (isDxp) {
      onDxpReady({
        fetchConfig,
        state,
        setState,
        setMicroFrontends,
        user,
      });
    } else {
      onGetProject();
      onApplicationReady({
        fetchConfig,
        fetchAuth,
        fetchCsrf,
        state,
        setState,
        setMicroFrontends,
        user,
      });
    }
  }, []);

  useEffect(() => {
    let removeEvents: () => void;
    if (isDxp) {
      hackRouter();
      removeEvents = registryLuigiEvents();
    }
    return () => {
      removeEvents?.call(null);
    };
  }, []);

  // TODO:
  useEffect(() => {
    console.log('State Updated', state);
    let removeEvents: () => void;
    if (!isDxp) {
      removeEvents = listenToEventBus({ state, setState });
    }
    return () => {
      removeEvents?.call(null);
    };
  }, []);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends, isDxp });
};

export default App;
